import React from 'react'

import SEO from '../components/seo'

const NotFoundPage = () => (
  <div>
    <SEO title="404: Página não encontrada" />
    <h1>404: Página não encontrada</h1>
  </div>
)

export default NotFoundPage
